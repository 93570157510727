import { AssetsManager } from '../game/AssetsManager';
import { Tile } from '../prefabs/tile';
import { TileType } from '../prefabs/TileTypes';
import { compareArrays, containsArray } from './Arrays';
import { randomInt } from './Math';

// Very basic algorithm for randomized levels
// Basically, is just playing the game kinda like player would do
export function legacyRandomize(
    layout: number[][],
    timeBoosters: number,
    heartBosters: number
): Tile[] {
    var layout: number[][];
    let tiles: Tile[] = [];
    let typeCounter: TileType = randomInt(19);

    // Generator of boosters order in map
    let boosters: Map<number, TileType> = new Map();

    for (let i = 0; i < timeBoosters; i++) {
        boosters.set(Math.floor((layout.length / 2) * Math.random()), TileType.Booster_Time);
    }

    for (let i = 0; i < heartBosters; i++) {
        boosters.set(Math.floor((layout.length / 2) * Math.random()), TileType.Booster_HP);
    }

    // Repeat untill level is complete
    while (layout.length > 0) {
        // Simple free positions filter (based on mahjong rules)
        const isFree = (pos: number[]) =>
            (!containsArray(layout, [pos[0] + 1, pos[1], pos[2]]) ||
                !containsArray(layout, [pos[0] - 1, pos[1], pos[2]])) &&
            !containsArray(layout, [pos[0], pos[1], pos[2] + 1]);

        // Step 1: Get all free positions
        let arrFree: number[][] = layout.filter(isFree);

        // Step 2: Pick two free random position
        // Shuffle array
        // WARN: Higher positions is preferd, cuz otherwise it could generate impossible levels
        const shuffled = arrFree.sort((a, b) => {
            return 0.5 - Math.random();
        });

        const sorted = shuffled.sort((a, b) => {
            if (a[2] > b[2]) return -1;
            return 1;
        });

        // Get sub-array of first 2 positions after shuffling and sorting
        let arrPlace = sorted.slice(0, 2);

        // Step 3: Pick pair's type.
        let pairType: TileType = 0;

        if (boosters.has(layout.length / 2)) {
            pairType = boosters.get(layout.length / 2)!;
        } else {
            if (typeCounter++ >= 18) {
                typeCounter = 0;
            }

            pairType = typeCounter;
        }

        arrPlace.forEach((placePos) => {
            // Step 4: Add tiles with selected positions
            tiles.push(new Tile(pairType, { x: placePos[0], y: placePos[1], z: placePos[2] }));

            // Step 5: Remove tiles positions from layout
            layout = layout.filter((pos: number[]) => !compareArrays(pos, placePos));
        });
    }

    return tiles;
}

import { Container, Graphics, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import { Scene } from '../game/Scene';
import { Tile } from '../prefabs/tile';
import { TileType } from '../prefabs/TileTypes';
import { Adaptive } from '../utils/Adaptive';
import { Circle, CircleTypes } from '../prefabs/Circle';
import { Winds } from '../prefabs/Winds';
import { StartScreenScene } from './StartScreenScene';
import { GameOverScene, GameOverType } from './GameOverScene';

export class LoadingScene extends Scene {
    constructor(callback: any) {
        super();

        let winds = new Winds();
        this.addChild(winds);

        let circle = new Circle(CircleTypes.Waves);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.iconWidth);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.addChild(circle);

        let mahjong = new Container();

        [
            TileType.M,
            TileType.A,
            TileType.H,
            TileType.J,
            TileType.O,
            TileType.N,
            TileType.G,
        ].forEach((type, index) => {
            let letter = new Tile(type);
            letter.scale.set((circle.width * 0.08) / letter.width);
            letter.x += (letter.width + 5) * index;

            mahjong.addChild(letter);
        });

        mahjong.pivot.set(mahjong.width, 0);
        mahjong.scale.set((circle.iconWidth * 0.75) / mahjong.width);
        mahjong.x = circle.x + circle.iconWidth / 2.05;
        mahjong.y = circle.y - circle.iconHeight / 2.22;
        this.addChild(mahjong);

        let winston = new Sprite(AssetsManager.getTexture('winston.png'));
        winston.scale.set((circle.iconWidth * 0.92) / winston.width);
        winston.x = circle.x - circle.iconWidth / 2.1;
        winston.y = circle.y - circle.iconHeight / 1.58;
        this.addChild(winston);

        let barbg = new Graphics()
            .beginFill(0xffffff, 0.5)
            .drawRoundedRect(
                circle.x - circle.iconWidth / 2,
                circle.y + circle.iconHeight / 2 + circle.iconHeight * 0.1,
                circle.iconWidth,
                circle.iconHeight * 0.025,
                circle.iconHeight
            );
        this.addChild(barbg);

        let barProgress = new Graphics();
        this.addChild(barProgress);

        Game.app.loader.onProgress.add((loader) => {
            this.removeChild(barProgress);
            barProgress = new Graphics()
                .beginFill(0xffffff)
                .drawRoundedRect(
                    circle.x - circle.iconWidth / 2,
                    circle.y + circle.iconHeight / 2 + circle.iconHeight * 0.1,
                    (circle.iconWidth * loader.progress) / 100,
                    circle.iconHeight * 0.025,
                    circle.iconHeight
                );
            this.addChild(barProgress);
        });

        Game.app.loader.onComplete.once(() => {
            callback();
        });

        Game.app.loader.load();

        if (!Game.app.loader.loading) {
            setTimeout(callback, 1);
        }

        // Colored bg
        Game.app.renderer.backgroundColor = 0x1a2e94;
    }
}

import { BLEND_MODES, Container, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import { Adaptive } from '../utils/Adaptive';

export class LevelBG extends Container {
    constructor(type: string) {
        super();
        type = type == undefined ? '1' : type;

        this.bgTypes[type].bind(this)();
    }

    readonly bgTypes: { [K: string]: Function } = {
        1: this.bg1,
        2: this.bg2,
        3: this.bg3,
        4: this.bg4,
        5: this.bg5,
        6: this.bg6,
        7: this.bg7,
        8: this.bg8,
        9: this.bg9,
        10: this.bg10,
    };

    bg1() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/1/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        for (let i = 0; i < 2; i++) {
            let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
            gradient.tint = 0x0052cc;
            gradient.anchor.set(0.5, 0.5);
            gradient.position.set(
                window.innerWidth / 2,
                window.innerHeight * i - 20 * Math.pow(-1, i)
            );
            gradient.scale.set((window.innerWidth * 2.5) / gradient.width);
            this.addChild(gradient);
        }

        let circle = new Sprite(AssetsManager.getTexture('levelbg/1/circle.png'));
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let waves = new Sprite(AssetsManager.getTexture('levelbg/1/waves.png'));
        waves.anchor.set(0.5, 1);
        waves.position.set(window.innerWidth / 2, window.innerHeight);
        waves.scale.set(window.innerWidth / waves.width);
        this.addChild(waves);
    }

    bg2() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/2/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        for (let i = 0; i < 2; i++) {
            let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
            gradient.tint = i == 0 ? 0xb55f4b : 0xf3a491;
            gradient.anchor.set(0.5, 0.5);
            gradient.position.set(
                window.innerWidth / 2,
                window.innerHeight * i - 20 * Math.pow(-1, i)
            );
            gradient.scale.set((window.innerWidth * 2.5) / gradient.width);
            this.addChild(gradient);
        }

        let circle = new Sprite(AssetsManager.getTexture('levelbg/2/circle.png'));
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let flowers = new Sprite(AssetsManager.getTexture('levelbg/2/flowers.png'));
        flowers.anchor.set(0.5, 1);
        flowers.position.set(window.innerWidth / 2, window.innerHeight);
        flowers.scale.set(window.innerWidth / flowers.width);
        this.addChild(flowers);
    }

    bg3() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/3/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0x54a0ea;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/3/circle.png'));
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let clouds = new Sprite(AssetsManager.getTexture('levelbg/3/clouds.png'));
        clouds.anchor.set(0.5, 1);
        clouds.position.set(window.innerWidth / 2, window.innerHeight);
        clouds.scale.set(window.innerWidth / clouds.width);
        this.addChild(clouds);
    }

    bg4() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/4/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0x529490;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/4/circle.png'));
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let fishes = new Sprite(AssetsManager.getTexture('levelbg/4/fishes.png'));
        fishes.anchor.set(0.5, 1);
        fishes.position.set(window.innerWidth / 2, window.innerHeight);
        fishes.scale.set(window.innerWidth / fishes.width);
        this.addChild(fishes);
    }

    bg5() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/5/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0x1a6272;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/5/circle.png'));
        circle.blendMode = BLEND_MODES.OVERLAY;
        circle.tint = 0x1d5f6e;
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let flowers = new Sprite(AssetsManager.getTexture('levelbg/5/flowers.png'));
        flowers.anchor.set(0.5, 1);
        flowers.position.set(window.innerWidth / 2, window.innerHeight);
        flowers.scale.set(window.innerWidth / flowers.width);
        this.addChild(flowers);
    }

    bg6() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/6/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0xca995e;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/6/circle.png'));
        circle.blendMode = BLEND_MODES.OVERLAY;
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let crane = new Sprite(AssetsManager.getTexture('levelbg/6/crane.png'));
        crane.anchor.set(0.5, 1);
        crane.position.set(window.innerWidth / 2, window.innerHeight);
        crane.scale.set(window.innerWidth / crane.width);
        this.addChild(crane);
    }

    bg7() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/7/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0xd7867c;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/7/circle.png'));
        circle.blendMode = BLEND_MODES.OVERLAY;
        circle.alpha = 0.9;
        circle.tint = 0xdc6867;
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let woods = new Sprite(AssetsManager.getTexture('levelbg/7/woods.png'));
        woods.anchor.set(0.5, 1);
        woods.position.set(window.innerWidth / 2, window.innerHeight);
        woods.scale.set(window.innerWidth / woods.width);
        this.addChild(woods);
    }

    bg8() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/8/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        for (let i = 0; i < 2; i++) {
            let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
            gradient.tint = 0x0052cc;
            gradient.anchor.set(0.5, 0.5);
            gradient.position.set(
                window.innerWidth / 2,
                window.innerHeight * i - 20 * Math.pow(-1, i)
            );
            gradient.scale.set((window.innerWidth * 2.5) / gradient.width);
            this.addChild(gradient);
        }

        let circle = new Sprite(AssetsManager.getTexture('levelbg/8/circle.png'));
        circle.blendMode = BLEND_MODES.OVERLAY;
        circle.alpha = 1;
        circle.tint = 0x909ce4;
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let towers = new Sprite(AssetsManager.getTexture('levelbg/8/towers.png'));
        towers.anchor.set(0.5, 1);
        towers.position.set(window.innerWidth / 2, window.innerHeight);
        towers.scale.set(window.innerWidth / towers.width);
        this.addChild(towers);
    }

    bg9() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/9/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0x1a6272;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, -20);
        gradient.scale.set((window.innerWidth * 3) / gradient.width);
        this.addChild(gradient);

        let circle = new Sprite(AssetsManager.getTexture('levelbg/9/circle.png'));
        circle.blendMode = BLEND_MODES.SCREEN;
        circle.alpha = 0.7;
        circle.tint = 0x084f5e;
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let leaves = new Sprite(AssetsManager.getTexture('levelbg/9/leaves.png'));
        leaves.anchor.set(0.5, 1);
        leaves.position.set(window.innerWidth / 2, window.innerHeight);
        leaves.scale.set(window.innerWidth / leaves.width);
        this.addChild(leaves);
    }

    bg10() {
        let bg = new Sprite(AssetsManager.getTexture('levelbg/1/bg.png'));
        bg.anchor.set(0.5, 0);
        bg.position.set(window.innerWidth / 2, 0);
        bg.scale.set(window.innerWidth / bg.width);
        this.addChild(bg);

        for (let i = 0; i < 2; i++) {
            let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
            gradient.tint = 0x0052cc;
            gradient.anchor.set(0.5, 0.5);
            gradient.position.set(
                window.innerWidth / 2,
                window.innerHeight * i - 20 * Math.pow(-1, i)
            );
            gradient.scale.set((window.innerWidth * 2.5) / gradient.width);
            this.addChild(gradient);
        }

        let circle = new Sprite(AssetsManager.getTexture('levelbg/10/circle.png'));
        circle.anchor.set(0.5, 0.5);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        circle.scale.set((Adaptive.contentWidth * 1.25) / circle.width);
        this.addChild(circle);

        let waves = new Sprite(AssetsManager.getTexture('levelbg/10/waves.png'));
        waves.anchor.set(0.5, 1);
        waves.position.set(window.innerWidth / 2, window.innerHeight);
        waves.scale.set(window.innerWidth / waves.width);
        this.addChild(waves);
    }
}

import gsap from 'gsap';
import { Application, Container, Sprite, Text } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import { Signal } from '../utils/Signals';

export class Timer extends Container {
    private _value: number = 0;

    set value(value: number) {
        this._value = Math.max(0, value);
        this.text.text =
            Math.floor(this._value / 60) +
            ':' +
            Math.floor((this._value % 60) / 10) +
            ((this._value % 60) % 10);
    }

    get value(): number {
        return this._value;
    }

    icon: Sprite;
    text: Text;

    readonly TimesUpSignal = new Signal();

    constructor() {
        super();

        this.icon = new Sprite(AssetsManager.getTexture('icons/clock.png'));
        this.addChild(this.icon);

        this.text = new Text('0:00', {
            fontFamily: 'SFProDisplay-Semibold',
            fontSize: this.icon.height * 0.85,
            fill: ['#ffffff'],
        });
        this.text.x = this.icon.width * 0.65 + this.text.width / 2;
        this.text.y = 1;
        this.text.anchor.set(0.5, 0);
        this.addChild(this.text);
    }

    start(time: number) {
        this.value = time;

        let i = setInterval(() => {
            if (this.value > 0) {
                this.value--;
                if (this.value <= 10)
                    gsap.to(this.text, { pixi: { tint: 0xe53333 }, duration: 0.7 });
            } else {
                clearInterval(i);
                this.TimesUpSignal.trigger();
            }
        }, 1000);
    }
}

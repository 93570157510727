import { Application, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';

export class EventIcon extends Sprite {
    type: EventIconType;

    constructor(type: EventIconType) {
        super(AssetsManager.getTexture('icons/' + EventIconType[type] + '.png'));

        this.type = type;
    }
}

export enum EventIconType {
    Booster_HP,
    Booster_Time,
    Punishment_Time,
}

import { Container, Graphics, Text } from 'pixi.js';

export class Button extends Container {
    constructor(type: ButtonType, label: string) {
        super();

        let button = new Graphics().beginFill(type).drawRoundedRect(0, 0, 343, 60, 12);
        this.addChild(button);

        let buttonLabel = new Text(label, {
            fontFamily: 'SFProDisplay-Bold',
            fontSize: 16,
            align: 'center',
        });

        switch (type) {
            case ButtonType.White:
                buttonLabel.style.fill = 0x000000;
                break;
            case ButtonType.Blue:
                buttonLabel.style.fill = 0xffffff;
                break;
        }

        buttonLabel.anchor.set(0.5, 0.5);
        buttonLabel.position.set(button.width / 2, button.height / 2);
        this.addChild(buttonLabel);
    }
}

export enum ButtonType {
    White = 0xffffff,
    Blue = 0x1a2e94,
}

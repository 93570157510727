import gsap from 'gsap';
import { CustomEase } from 'gsap/all';
import { Container, TimeLimiter } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import { compareArrays, containsArray } from '../utils/Arrays';
import { DataSignal, Signal } from '../utils/Signals';
import { legacyRandomize } from '../utils/TilesRandomizer';
import { Tile } from './tile';
import { TileType } from './TileTypes';

export class Board extends Container {
    tiles: Tile[] = [];
    positions: number[][] = [];
    selection: Tile[] = [];

    // For level re-geneneration in case of impossible to solve situation
    boosters = {
        Time: 0,
        HP: 0,
    };

    readonly MatchSignal: DataSignal<TileType> = new DataSignal();
    readonly MistakeSignal: Signal = new Signal();
    readonly CompleteSignal: Signal = new Signal();

    constructor(data: any) {
        super();

        this.boosters = { Time: data.Booster_Time, HP: data.Booster_Heart };

        let layout = AssetsManager.getData('data/layouts.json')[data.Layout_Option];
        this.generateLayout(layout);

        // For tiles overlaping based on zIndex
        this.sortableChildren = true;
    }

    generateLayout(layout: number[][]) {
        this.tiles.forEach((tile) => this.removeChild(tile));
        this.positions = [];

        this.tiles = legacyRandomize(layout, this.boosters.Time, this.boosters.HP);

        // Setup tiles positions array (just for simple tile availability checking)
        this.tiles.forEach((tile) => {
            this.positions.push([tile.coords.x, tile.coords.y, tile.coords.z]);
        });

        this.tiles.forEach(this.spawnTile.bind(this));
        this.tiles.forEach(this.bindInteractions.bind(this));
        this.tiles.forEach(this.updateTile.bind(this));
    }

    // Spawn tile based on coordinates
    spawnTile(tile: Tile) {
        tile.pivot.set(tile.width / 2, tile.height / 2);
        tile.x +=
            tile.coords.x * Tile.clipWidth +
            tile.width / 2 -
            Tile.upOffset.x * tile.coords.z +
            Tile.upOffset.x;
        tile.y +=
            tile.coords.y * Tile.clipHeight +
            tile.height / 2 -
            Tile.upOffset.y * tile.coords.z +
            Tile.upOffset.y;

        // Multipling all coordinates to make tiles look promisable
        tile.zIndex = (tile.coords.x + 1) * (tile.coords.y + 1) * (tile.coords.z + 1);

        this.addChild(tile);
    }

    // Remove pair of tiles from board
    removePair(pair: Tile[]) {
        // Tracking boosters usage
        switch (pair[0].type) {
            case TileType.Booster_HP:
                this.boosters.HP--;
                break;

            case TileType.Booster_Time:
                this.boosters.Time--;
                break;
        }

        pair.forEach((tile) => {
            this.tiles.splice(this.tiles.indexOf(tile), 1);

            this.positions.forEach((pos, index) => {
                if (compareArrays(pos, [tile.coords.x, tile.coords.y, tile.coords.z]))
                    this.positions.splice(index, 1);
            });

            tile.zIndex = 10000;
            gsap.to(tile, {
                pixi: { y: tile.y + 5000, angle: 360 * (0.5 - Math.random()) },
                duration: 12,
                ease: CustomEase.create(
                    'custom',
                    'M0,0 C0.094,-0.21 0.178,0.718 0.332,0.896 0.504,1.095 0.818,1.001 1,1 '
                ),
                onComplete: () => {
                    this.removeChild(tile);
                },
            });

            gsap.to(tile, {
                pixi: { angle: 90 * (0.5 - Math.random()) },
                duration: 2,
            });
        });

        if (this.tiles.length == 0) {
            this.CompleteSignal.trigger();
        } else {
            // Check for impossible situation
            let avalibleTiles = this.tiles.filter(this.isAvailable);

            let tileTypes: TileType[] = [];
            avalibleTiles.forEach((tile) => {
                if (!tileTypes.includes(tile.type)) tileTypes.push(tile.type);
            });

            let isImpossible = avalibleTiles.length <= tileTypes.length;

            // Generate new layout if level is impossible to complete
            if (isImpossible) {
                this.generateLayout(this.positions);
            } else {
                this.tiles.forEach(this.updateTile.bind(this));
            }

            this.naborKostiley();
        }
    }

    // If something go wrong it might help
    naborKostiley() {
        // Remove tiles, if they are on top of/inside each other
        if (
            this.tiles.length == 2 &&
            this.tiles[0].coords.x == this.tiles[1].coords.x &&
            this.tiles[0].coords.y == this.tiles[1].coords.y
        ) {
            this.removePair([this.tiles[0], this.tiles[1]]);
        }

        // Remove lonely tile
        if (this.tiles.length == 1) {
            this.removePair([this.tiles[0]]);
        }
    }

    // I'm so sorry... x2
    bindInteractions(tile: Tile) {
        tile.on('pointerdown', () => {
            if (this.selection.length < 2) {
                if (this.selection.length == 0) {
                    this.selection.push(tile);
                    gsap.to(tile, { pixi: { tint: 0x54fde0 }, duration: 0.2 });
                } else if (this.selection.includes(tile)) {
                    this.selection = [];
                    gsap.to(tile, { pixi: { tint: 0xffffff }, duration: 0.2 });
                } else {
                    this.selection.push(tile);

                    if (this.selection[0].type == this.selection[1].type) {
                        this.selection.forEach((tile) => {
                            tile.interactive = false;
                        });

                        gsap.to(tile, {
                            pixi: { tint: 0x54fde0 },
                            duration: 0.2,
                            onComplete: () => {
                                this.MatchSignal.trigger(this.selection[0].type);
                                this.removePair(this.selection);
                                this.selection = [];
                            },
                        });
                    } else {
                        this.MistakeSignal.trigger();
                        this.selection.forEach((tile) => {
                            gsap.fromTo(
                                tile,
                                {
                                    pixi: { tint: 0xffffff },
                                },
                                {
                                    pixi: { tint: 0xf65555 },
                                    duration: 0.2,
                                    yoyo: true,
                                    repeat: 1,
                                    repeatDelay: 0.1,
                                    onComplete: () => {
                                        this.selection = [];
                                    },
                                }
                            );
                        });
                    }
                }
            }
        });
    }

    isAvailable = (tile: Tile) => {
        let pos = [tile.coords.x, tile.coords.y, tile.coords.z];
        return (
            (!containsArray(this.positions, [pos[0] + 1, pos[1], pos[2]]) ||
                !containsArray(this.positions, [pos[0] - 1, pos[1], pos[2]])) &&
            !containsArray(this.positions, [pos[0], pos[1], pos[2] + 1])
        );
    };

    // Function to check tiles availability and make them interactive
    updateTile(tile: Tile) {
        if (this.isAvailable(tile)) {
            gsap.to(tile, {
                pixi: { tint: 0xffffff },
                duration: 0.2,
                onComplete: () => {
                    tile.interactive = true;
                },
            });
        } else {
            tile.interactive = false;
            gsap.to(tile, { pixi: { tint: 0x9c9c9e }, duration: 0.2 });
        }
    }
}

export enum LayoutType {
    One = 1,
    Two = 2,
    Three = 3,
}

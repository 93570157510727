import { Container, Sprite, Text } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import { ProgressManager } from '../game/ProgressManager';
import { Signal } from '../utils/Signals';

export class HP extends Container {
    icon: Sprite;
    text: Text;

    readonly NoHPSignal: Signal = new Signal();

    constructor() {
        super();

        this.icon = new Sprite(AssetsManager.getTexture('icons/hearth.png'));
        this.addChild(this.icon);

        this.text = new Text('x' + ProgressManager.HP, {
            fontFamily: 'SFProDisplay-Semibold',
            fontSize: this.icon.height,
            fill: ['#ffffff'],
        });
        this.text.x = this.icon.width * 0.36;
        this.text.y = this.icon.height * 0.1;
        this.addChild(this.text);
    }
}

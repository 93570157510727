import { Application, Container } from 'pixi.js';
import { Scene } from './Scene';

// VERY basic scene manager
export default class Game {
    static app: Application;
    static currentScene: Scene;
    static container: Container = new Container();

    static init(app: Application) {
        this.app = app;
    }

    static switchScene(scene: Scene) {
        if (this.currentScene !== undefined) this.container.removeChild(this.currentScene);

        this.currentScene = scene;
        this.container.addChild(this.currentScene);
    }

    static update(delta: number) {
        if (this.currentScene === undefined) return;

        this.currentScene.update(delta);
    }

    static onResize(width: number, height: number) {
        if (this.currentScene === undefined) return;

        this.currentScene.onResize(width, height);
    }
}

import { Container, Point, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import { TileType } from './TileTypes';

export class Tile extends Container {
    type: TileType;
    coords: { x: number; y: number; z: number };

    base: Sprite;
    icon: Sprite;

    private _tint: number = 0xffffff;
    set tint(value: number) {
        this._tint = value;
        this.base.tint = value;
        this.icon.tint = value;
    }
    get tint(): number {
        return this._tint;
    }

    // Some constants about tile's measures
    static clipWidth = 153.6;
    static clipHeight = 199.89;
    static upOffset: Point = new Point(12, 12);

    constructor(type: TileType, coords: Tile['coords'] = { x: 0, y: 0, z: 0 }) {
        super();
        this.type = type;
        this.coords = coords;

        this.base = new Sprite(AssetsManager.getTexture('tiles/Base.png'));
        this.addChild(this.base);

        this.icon = new Sprite(AssetsManager.getTexture('tiles/' + TileType[this.type] + '.png'));
        this.addChild(this.icon);

        this.interactive = true;
    }
}

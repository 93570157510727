import { Graphics, Sprite } from 'pixi.js';
import { Board, LayoutType } from '../prefabs/Board';
import { HP } from '../prefabs/HP';
import { PopUp, PopUpType } from '../prefabs/PopUp';
import { Timer } from '../prefabs/Timer';
import { EventIcon, EventIconType } from '../prefabs/EventIcon';
import gsap, { Cubic } from 'gsap';
import { Scene } from '../game/Scene';
import { Adaptive } from '../utils/Adaptive';
import { TileType } from '../prefabs/TileTypes';
import Game from '../game/Game';
import { GameOverScene, GameOverType } from './GameOverScene';
import { AssetsManager } from '../game/AssetsManager';
import { ProductCardScene } from './ProductCardScene';
import { LevelBG } from '../prefabs/LevelBG';
import { ProgressManager } from '../game/ProgressManager';
import { IGameDataLevel } from '../interfaces/IGameDataLevel';
import Api from '../services/api';
import { StartScreenScene } from './StartScreenScene';

export class PlayScene extends Scene {
    board: Board;
    hp: HP;

    // Timer Section
    timer: Timer;

    static levelData: IGameDataLevel;

    constructor(levelData: any = undefined) {
        super();

        if (levelData) PlayScene.levelData = levelData;

        this.board = new Board(PlayScene.levelData.configuration);
        this.board.pivot.set(this.board.width / 2, this.board.height / 2);
        this.board.scale.set((Adaptive.contentWidth - 64) / this.board.width);
        this.board.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let levelBG = new LevelBG(PlayScene.levelData.configuration.Background);
        this.addChild(levelBG);

        let graphic = new Graphics();
        graphic.beginFill(0xff00ff, 0.4);
        graphic.drawRect(
            window.innerWidth / 2 - Adaptive.contentWidth / 2,
            window.innerHeight / 2 - Adaptive.contentHeight / 2,
            Adaptive.contentWidth,
            Adaptive.contentHeight
        );
        // graphic.beginFill(0xffff00, 0.4);
        // graphic.drawRect(this.board.x, this.board.y, this.board.width, this.board.height);

        // this.addChild(graphic);
        this.addChild(this.board);

        this.hp = new HP();
        this.hp.scale.set((Adaptive.contentWidth * 0.14) / this.hp.width);
        this.hp.x = window.innerWidth / 2 - Adaptive.contentWidth / 2 + 10;
        this.hp.y = (this.board.y - this.board.height / 2) / 3;
        this.addChild(this.hp);

        this.timer = new Timer();
        this.timer.scale.set(this.hp.height / this.timer.height);
        this.timer.x = window.innerWidth / 2 + Adaptive.contentWidth / 2 - this.timer.width - 4;
        this.timer.y = this.hp.y;
        this.addChild(this.timer);

        // Setup hp signal
        this.hp.NoHPSignal.on(() => this.openPopUp(PopUpType.NoHP));

        // Setup timer signal
        this.timer.start(PlayScene.levelData.configuration.Timing);
        this.timer.TimesUpSignal.on(() => {
            if (this.board.tiles.length != 0) {
                ProgressManager.HP -= 1;
                this.openPopUp(ProgressManager.HP > 0 ? PopUpType.TimesUp : PopUpType.NoHP);
                Api.loseGame();
            }
        });

        let exit = new Sprite(AssetsManager.getTexture('exit.png'));
        exit.anchor.set(0.5, 0);
        exit.scale.set((this.timer.height * 0.9) / exit.height);
        exit.position.set(window.innerWidth / 2, this.timer.y * 0.3);
        exit.interactive = true;
        exit.on('pointerdown', () => {
            this.openPopUp(PopUpType.Quit);
        });
        this.addChild(exit);

        // Setup board signals
        this.board.MatchSignal.on(this.onMatch.bind(this));
        this.board.MistakeSignal.on(this.onMistake.bind(this));
        this.board.CompleteSignal.on(this.onComplete.bind(this));
    }

    onMatch(tileType: TileType) {
        switch (tileType) {
            case TileType.Booster_Time:
                this.timer.value += 30;
                this.showEventIcon(EventIconType.Booster_Time);
                break;

            case TileType.Booster_HP:
                ProgressManager.HP += 1;
                this.hp.text.text = 'x' + ProgressManager.HP;
                this.showEventIcon(EventIconType.Booster_HP);
                break;
        }
    }

    mistakesCounter: number = 0;
    onMistake() {
        if (++this.mistakesCounter > 2) {
            this.timer.value -= 10;
            this.showEventIcon(EventIconType.Punishment_Time);
        }
    }

    async onComplete() {
        const isBonusReward = await Api.handleLevelComplete(PlayScene.levelData.key);
        const gameOverType = isBonusReward ? GameOverType.WinPrimary : GameOverType.WinSecondary;
        Game.switchScene(new GameOverScene(gameOverType));
    }

    // Not the best implementation, but it's fine.
    icons: EventIcon[] = [];

    // I really don't know how else I can name it
    showEventIcon(type: EventIconType) {
        let icon = new EventIcon(type);
        icon.scale.set((Adaptive.contentWidth * 0.16) / icon.width);
        icon.position.y = this.timer.y + this.timer.height + 4;
        this.addChild(icon);

        switch (type) {
            case EventIconType.Booster_HP:
                icon.position.x = window.innerWidth / 2 - Adaptive.contentWidth / 2 + 6;
                this.icons.forEach((icon) => {
                    if (icon.type == type) this.removeChild(icon);
                });
                break;

            default:
                icon.position.x =
                    window.innerWidth / 2 + Adaptive.contentWidth / 2 - icon.width - 6;
                this.icons.forEach((icon) => {
                    if (icon.type != EventIconType.Booster_HP) this.removeChild(icon);
                });
                break;
        }
        this.icons.push(icon);

        gsap.fromTo(
            icon,
            {
                pixi: { y: icon.y, alpha: 0 },
            },
            {
                pixi: { y: icon.y - 5, alpha: 1 },
                duration: 0.5,
                yoyo: true,
                repeat: 1,
                repeatDelay: 10,
                onComplete: () => {
                    this.icons.splice(this.icons.indexOf(icon), 1);
                },
            }
        );
    }

    openPopUp(type: PopUpType) {
        let bg = new Graphics()
            .beginFill(0x000000, 0.7)
            .drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.addChild(bg);

        // Make sure that player interacte with anything just pop up
        bg.interactive = true;

        let popUp = new PopUp(type);
        popUp.pivot.set(popUp.width / 2, popUp.height / 2);
        popUp.scale.set((Adaptive.contentWidth - 20) / popUp.width);
        popUp.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.addChild(popUp);

        gsap.fromTo(popUp, { alpha: 0 }, { alpha: 1, duration: 0.4 });

        switch (type) {
            case PopUpType.Quit:
                popUp.CloseSignal.on(() => {
                    gsap.to([popUp, bg], {
                        alpha: 0,
                        duration: 0.2,
                        onComplete: () => {
                            this.removeChild(popUp);
                            this.removeChild(bg);
                        },
                    });
                });
                popUp.AcceptSignal.on(() => {
                    ProgressManager.HP -= 1;
                    Game.switchScene(new StartScreenScene());
                });
                break;

            default:
                const fn = () => {
                    Game.switchScene(new GameOverScene(GameOverType.Lose));
                };

                popUp.CloseSignal.on(fn);
                popUp.AcceptSignal.on(fn);
                break;
        }
    }
}

import { Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import { Scene } from '../game/Scene';
import { Winds } from '../prefabs/Winds';
import { Button, ButtonType } from '../prefabs/Button';
import { Adaptive } from '../utils/Adaptive';
import { LevelsScene } from './LevelsScene';
import { PlayScene } from './PlayScene';
import { t } from '../services/localization';

export class ProductCardScene extends Scene {
    constructor() {
        super();

        let winds = new Winds();
        winds.alpha = 0.3;
        this.addChild(winds);

        let gradient = new Sprite(AssetsManager.getTexture('80gradient.png'));
        gradient.tint = 0x64a2ff;
        gradient.anchor.set(0.5, 0.5);
        gradient.position.set(window.innerWidth / 2, window.innerHeight);
        gradient.scale.set(Adaptive.contentWidth * 2, 1);
        this.addChild(gradient);

        let button1 = new Button(ButtonType.White, t('products_restart_btn'));
        button1.pivot.set(button1.width / 2, button1.height / 2);
        button1.scale.set((Adaptive.contentWidth - 30) / button1.width);
        button1.position.set(window.innerWidth / 2, window.innerHeight * 0.8);
        button1.interactive = true;
        button1.on('pointerdown', this.restartPressed);
        this.addChild(button1);

        let button2 = new Button(ButtonType.Blue, t('products_levels_btn'));
        button2.pivot.set(button2.width / 2, button2.height / 2);
        button2.scale.set((Adaptive.contentWidth - 30) / button2.width);
        button2.position.set(window.innerWidth / 2, window.innerHeight * 0.9);
        button2.interactive = true;
        button2.on('pointerdown', this.levelsPressed);
        this.addChild(button2);

        let SKUImage = PlayScene.levelData.assets.find((v) => v.name.startsWith('SKU'));
        let SKU = Sprite.from(SKUImage!.url);
        SKU.anchor.set(0.5, 0.5);
        SKU.position.set(window.innerWidth / 2, (button2.y - button2.height / 2) / 2);
        SKU.scale.set(Adaptive.contentWidth / SKU.width);
        this.addChild(SKU);

        // Colored bg
        Game.app.renderer.backgroundColor = 0x1a2e94;
    }

    restartPressed() {
        Game.switchScene(new PlayScene());
    }

    levelsPressed() {
        Game.switchScene(new LevelsScene());
    }
}

import { Container, Graphics, InteractionEvent, Sprite, Text, TextStyle } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import { Scene } from '../game/Scene';
import { Tile } from '../prefabs/tile';
import { TileType } from '../prefabs/TileTypes';
import { Timer } from '../prefabs/Timer';
import { Button, ButtonType } from '../prefabs/Button';
import { Adaptive } from '../utils/Adaptive';
import { StartScreenScene } from './StartScreenScene';
import { t } from '../services/localization';

export class RulesScene extends Scene {
    pages: Container[];
    curpage: Container;

    constructor() {
        super();

        let wind = new Sprite(AssetsManager.getTexture('wind.png'));
        wind.scale.set((window.innerHeight * 1.2) / wind.height);
        this.addChild(wind);

        // let back = new Sprite(AssetsManager.getTexture('back.png'));
        // back.scale.set(((Adaptive.contentWidth - 20) * 0.08) / back.height);
        // back.position.set(window.innerWidth * 0.05, (Adaptive.contentWidth - 20) * 0.08);
        // back.tint = 0x000000;
        // back.interactive = true;
        // back.on('pointerdown', this.backPressed);
        // this.addChild(back);

        this.pages = [this.page1(), this.page2(), this.page3(), this.page4(), this.page5()];
        this.curpage = this.pages[0];
        this.addChild(this.curpage);

        this.interactive = true;
        this.on('pointerdown', this.changePage);

        // Colored bg
        Game.app.renderer.backgroundColor = 0xffffff;
    }

    changePage(e: InteractionEvent) {
        this.removeChild(this.curpage);
        this.curpage = this.pages[
            Math.max(
                0,
                Math.min(
                    this.pages.length - 1,
                    this.pages.indexOf(this.curpage) +
                        (e.data.global.x > window.innerWidth / 2 ? 1 : -1)
                )
            )
        ];
        this.addChild(this.curpage);
    }

    page1(): Container {
        let page = new Container();

        let circle = new Sprite(AssetsManager.getTexture('circles/Blue.png'));
        circle.anchor.set(0.5, 0.5);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.width);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        page.addChild(circle);

        let cloudUp = new Sprite(AssetsManager.getTexture('cloudUp.png'));
        cloudUp.scale.set(window.innerWidth / cloudUp.width);
        cloudUp.position.set(0, circle.y - circle.height / 2.2);
        page.addChild(cloudUp);

        let cloudDown = new Sprite(AssetsManager.getTexture('cloudDown.png'));
        cloudDown.anchor.set(0, 1);
        cloudDown.scale.set(window.innerWidth / cloudDown.width);
        cloudDown.position.set(0, circle.y + circle.height / 2.35);
        page.addChild(cloudDown);

        let textStyle = new TextStyle({
            fontFamily: 'SFProDisplay-Bold',
            fontSize: circle.width * 0.05,
            align: 'center',
        });

        let tile1 = new Tile(TileType.Leaf);
        tile1.pivot.set(tile1.width, tile1.height);
        tile1.scale.set((circle.width * 0.2) / tile1.width);
        tile1.position.set(window.innerWidth / 2, window.innerHeight / 2);
        tile1.tint = 0x55fee0;

        let tile2 = new Tile(TileType.Bamboo_Four);
        tile2.pivot.set(tile2.width, 0);
        tile2.scale.set(tile1.scale.x);
        tile2.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile3 = new Tile(TileType.Bamboo_Four);
        tile3.pivot.set(0, tile3.height);
        tile3.scale.set(tile1.scale.x);
        tile3.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile4 = new Tile(TileType.Leaf);
        tile4.scale.set(tile1.scale.x);
        tile4.position.set(window.innerWidth / 2, window.innerHeight / 2);
        tile4.tint = 0x55fee0;

        let upText = new Text(t('rules_1_1'), textStyle);
        upText.anchor.set(0.5, 0.5);
        upText.position.set(window.innerWidth / 2, circle.y - circle.height / 1.5);
        page.addChild(upText);

        let downText = new Text(t('rules_1_2'), textStyle);
        downText.anchor.set(0.5, 0.5);
        downText.position.set(window.innerWidth / 2, circle.y + circle.height / 1.5);
        page.addChild(downText);

        page.addChild(tile1);
        page.addChild(tile2);
        page.addChild(tile3);
        page.addChild(tile4);

        let pagesCounter = new Graphics()
            .beginFill(0x0056c3)
            .drawCircle(
                circle.x - circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x - circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(circle.x, circle.y + circle.height * 0.9, circle.width * 0.015)
            .drawCircle(
                circle.x + circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x + circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            );
        page.addChild(pagesCounter);

        return page;
    }

    page2(): Container {
        let page = new Container();

        let circle = new Sprite(AssetsManager.getTexture('circles/Blue.png'));
        circle.anchor.set(0.5, 0.5);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.width);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        page.addChild(circle);

        let cloudUp = new Sprite(AssetsManager.getTexture('cloudUp.png'));
        cloudUp.scale.set(window.innerWidth / cloudUp.width);
        cloudUp.position.set(0, circle.y - circle.height / 2.2);
        page.addChild(cloudUp);

        let cloudDown = new Sprite(AssetsManager.getTexture('cloudDown.png'));
        cloudDown.anchor.set(0, 1);
        cloudDown.scale.set(window.innerWidth / cloudDown.width);
        cloudDown.position.set(0, circle.y + circle.height / 2.35);
        page.addChild(cloudDown);

        let textStyle = new TextStyle({
            fontFamily: 'SFProDisplay-Bold',
            fontSize: circle.width * 0.05,
            align: 'center',
        });

        let tile1 = new Tile(TileType.Leaf);
        tile1.pivot.set(tile1.width, tile1.height);
        tile1.scale.set((circle.width * 0.2) / tile1.width);
        tile1.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile2 = new Tile(TileType.Bamboo_Four);
        tile2.pivot.set(tile2.width, 0);
        tile2.scale.set(tile1.scale.x);
        tile2.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile3 = new Tile(TileType.Bamboo_Four);
        tile3.pivot.set(0, tile3.height);
        tile3.scale.set(tile1.scale.x);
        tile3.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile4 = new Tile(TileType.Leaf);
        tile4.scale.set(tile1.scale.x);
        tile4.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile5 = new Tile(TileType.Leaf);
        tile5.pivot.set(tile5.width, tile5.height / 2);
        tile5.scale.set(tile1.scale.x);
        tile5.position.set(window.innerWidth / 2 - tile1.width / 2, window.innerHeight / 2);
        tile5.tint = 0x9a9b9e;

        let tile6 = new Tile(TileType.Bamboo_Four);
        tile6.pivot.set(0, tile6.height / 2);
        tile6.scale.set(tile1.scale.x);
        tile6.position.set(window.innerWidth / 2 + tile1.width / 2, window.innerHeight / 2);
        tile6.tint = 0x9a9b9e;

        let upText = new Text(t('rules_2_1'), textStyle);
        upText.anchor.set(0.5, 0.5);
        upText.position.set(window.innerWidth / 2, circle.y - circle.height / 1.5);
        page.addChild(upText);

        let downText = new Text(t('rules_2_2'), textStyle);
        downText.anchor.set(0.5, 0.5);
        downText.position.set(window.innerWidth / 2, circle.y + circle.height / 1.65);
        page.addChild(downText);

        let timer = new Timer();
        timer.text.style.fill = ['#203599'];
        timer.pivot.set(timer.width / 2, 0);
        timer.scale.set(downText.width / 1.5 / timer.width);
        timer.position.set(downText.x, downText.y * 1.035);
        page.addChild(timer);

        page.addChild(tile5);
        page.addChild(tile6);
        page.addChild(tile1);
        page.addChild(tile2);
        page.addChild(tile3);
        page.addChild(tile4);

        let pagesCounter = new Graphics()
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x - circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3)
            .drawCircle(
                circle.x - circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3, 0.3)
            .drawCircle(circle.x, circle.y + circle.height * 0.9, circle.width * 0.015)
            .drawCircle(
                circle.x + circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x + circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            );
        page.addChild(pagesCounter);

        return page;
    }

    page3(): Container {
        let page = new Container();

        let circle = new Sprite(AssetsManager.getTexture('circles/Blue.png'));
        circle.anchor.set(0.5, 0.5);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.width);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        page.addChild(circle);

        let cloudUp = new Sprite(AssetsManager.getTexture('cloudUp.png'));
        cloudUp.scale.set(window.innerWidth / cloudUp.width);
        cloudUp.position.set(0, circle.y - circle.height / 2.2);
        page.addChild(cloudUp);

        let cloudDown = new Sprite(AssetsManager.getTexture('cloudDown.png'));
        cloudDown.anchor.set(0, 1);
        cloudDown.scale.set(window.innerWidth / cloudDown.width);
        cloudDown.position.set(0, circle.y + circle.height / 2.35);
        page.addChild(cloudDown);

        let textStyle = new TextStyle({
            fontFamily: 'SFProDisplay-Bold',
            wordWrap: true,
            wordWrapWidth: 400,
            fontSize: circle.width * 0.05,
            align: 'center',
        });

        let tile1 = new Tile(TileType.Leaf);
        tile1.pivot.set(tile1.width, tile1.height);
        tile1.scale.set((circle.width * 0.2) / tile1.width);
        tile1.position.set(window.innerWidth / 2, window.innerHeight / 2);
        tile1.tint = 0x55fee0;

        let tile2 = new Tile(TileType.Bamboo_Four);
        tile2.pivot.set(tile2.width, 0);
        tile2.scale.set(tile1.scale.x);
        tile2.position.set(window.innerWidth / 2, window.innerHeight / 2);
        tile2.tint = 0xfaa5a8;

        let tile3 = new Tile(TileType.Bamboo_Four);
        tile3.pivot.set(0, tile3.height);
        tile3.scale.set(tile1.scale.x);
        tile3.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile4 = new Tile(TileType.Leaf);
        tile4.scale.set(tile1.scale.x);
        tile4.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let tile5 = new Tile(TileType.Leaf);
        tile5.pivot.set(tile5.width, tile5.height / 2);
        tile5.scale.set(tile1.scale.x);
        tile5.position.set(window.innerWidth / 2 - tile1.width / 2, window.innerHeight / 2);
        tile5.tint = 0x9a9b9e;

        let tile6 = new Tile(TileType.Bamboo_Four);
        tile6.pivot.set(0, tile6.height / 2);
        tile6.scale.set(tile1.scale.x);
        tile6.position.set(window.innerWidth / 2 + tile1.width / 2, window.innerHeight / 2);
        tile6.tint = 0x9a9b9e;

        let upText = new Text(t('rules_3_1'), textStyle);
        upText.anchor.set(0.5, 0.5);
        upText.position.set(window.innerWidth / 2, circle.y - circle.height / 1.5);
        page.addChild(upText);

        let downText = new Text(t('rules_3_2'), textStyle);
        downText.anchor.set(0.5, 0.5);
        downText.position.set(window.innerWidth / 2, circle.y + circle.height / 1.45);
        page.addChild(downText);

        page.addChild(tile5);
        page.addChild(tile6);
        page.addChild(tile1);
        page.addChild(tile2);
        page.addChild(tile3);
        page.addChild(tile4);

        let pagesCounter = new Graphics()
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x - circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x - circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3)
            .drawCircle(circle.x, circle.y + circle.height * 0.9, circle.width * 0.015)
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x + circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x + circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            );
        page.addChild(pagesCounter);

        return page;
    }

    page4(): Container {
        let page = new Container();

        let circle = new Sprite(AssetsManager.getTexture('circles/Blue.png'));
        circle.anchor.set(0.5, 0.5);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.width);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        page.addChild(circle);

        let cloudUp = new Sprite(AssetsManager.getTexture('cloudUp.png'));
        cloudUp.scale.set(window.innerWidth / cloudUp.width);
        cloudUp.position.set(0, circle.y - circle.height / 1.48);
        page.addChild(cloudUp);

        let cloudDown = new Sprite(AssetsManager.getTexture('cloudDown.png'));
        cloudDown.anchor.set(0, 1);
        cloudDown.scale.set(window.innerWidth / cloudDown.width);
        cloudDown.position.set(0, circle.y + circle.height / 1.45);
        page.addChild(cloudDown);

        let textStyle = new TextStyle({
            fontFamily: 'SFProDisplay-Bold',
            fontSize: circle.width * 0.05,
            align: 'left',
        });

        let tile1 = new Tile(TileType.Booster_HP);
        tile1.pivot.set(tile1.width, tile1.height);
        tile1.scale.set((circle.width * 0.2) / tile1.width);
        tile1.position.set(
            circle.x - circle.width / 2 + circle.width * 0.26,
            window.innerHeight / 2 - circle.height * 0.03
        );
        page.addChild(tile1);

        let text1 = new Text(t('rules_4_1'), textStyle.clone());
        text1.anchor.set(0, 0.5);
        text1.style.fill = 0xffffff;
        text1.position.set(tile1.x + tile1.width * 0.35, tile1.y - tile1.height / 2);
        page.addChild(text1);

        let tile2 = new Tile(TileType.Booster_Time);
        tile2.pivot.set(tile2.width, 0);
        tile2.scale.set(tile1.scale.x);
        tile2.position.set(tile1.x, window.innerHeight / 2 + circle.height * 0.03);
        page.addChild(tile2);

        let text2 = new Text(t('rules_4_2'), textStyle.clone());
        text2.anchor.set(0, 0.5);
        text2.style.fill = 0xffffff;
        text2.position.set(text1.x, tile2.y + tile2.height / 2);
        page.addChild(text2);

        let upText = new Text(t('rules_4_3'), textStyle.clone());
        upText.style.fill = 0x000000;
        upText.anchor.set(0.5, 0.5);
        upText.position.set(window.innerWidth / 2, circle.y - circle.height / 1.5);
        page.addChild(upText);

        let pagesCounter = new Graphics()
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x - circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x - circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(circle.x, circle.y + circle.height * 0.9, circle.width * 0.015)
            .beginFill(0x0056c3)
            .drawCircle(
                circle.x + circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x + circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            );
        page.addChild(pagesCounter);

        return page;
    }

    page5(): Container {
        let page = new Container();

        let circle = new Sprite(AssetsManager.getTexture('circles/Blue.png'));
        circle.anchor.set(0.5, 0.5);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.width);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);

        let cloudUp = new Sprite(AssetsManager.getTexture('cloudUp.png'));
        cloudUp.scale.set(window.innerWidth / cloudUp.width);
        cloudUp.position.set(0, circle.y - circle.height / 1.4);
        page.addChild(cloudUp);

        let cloudDown = new Sprite(AssetsManager.getTexture('cloudDown.png'));
        cloudDown.anchor.set(0, 1);
        cloudDown.scale.set(window.innerWidth / cloudDown.width);
        cloudDown.position.set(0, circle.y + circle.height / 1.3);
        page.addChild(cloudDown);

        let textStyle = new TextStyle({
            fontFamily: 'SFProDisplay-Bold',
            fontSize: circle.width * 0.05,
            align: 'center',
        });

        let text = new Text(t('rules_5_1'), textStyle.clone());
        text.style.fill = 0x000000;
        text.anchor.set(0.5, 0.5);
        text.position.set(window.innerWidth / 2, window.innerHeight / 2);
        page.addChild(text);

        let button = new Button(ButtonType.Blue, t('rules_btn'));
        button.pivot.set(button.width / 2, button.height / 2);
        button.scale.set((Adaptive.contentWidth - 30) / button.width);
        button.position.set(circle.x, circle.y + circle.height * 0.67);
        button.interactive = true;
        button.on('pointerdown', this.backPressed);
        page.addChild(button);

        let pagesCounter = new Graphics()
            .beginFill(0x0056c3, 0.3)
            .drawCircle(
                circle.x - circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(
                circle.x - circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .drawCircle(circle.x, circle.y + circle.height * 0.9, circle.width * 0.015)
            .drawCircle(
                circle.x + circle.width * 0.08,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            )
            .beginFill(0x0056c3)
            .drawCircle(
                circle.x + circle.width * 0.16,
                circle.y + circle.height * 0.9,
                circle.width * 0.015
            );
        page.addChild(pagesCounter);

        return page;
    }

    backPressed() {
        Game.switchScene(new StartScreenScene());
    }
}

export enum TileType {
    Star_One,
    Star_Two,
    Star_Three,
    Star_Four,
    Star_Five,
    Star_Six,
    Bamboo_One,
    Bamboo_Two,
    Bamboo_Three,
    Bamboo_Four,
    Bamboo_Six,
    Winston_One,
    Winston_Two,
    Bird,
    Waves,
    Leaf,
    Drum,
    Green_Bamboo,
    W,
    Booster_HP,
    Booster_Time,
    M,
    A,
    H,
    J,
    O,
    N,
    G,
}

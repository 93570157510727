import { Application, Container, Graphics, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';

export class Winds extends Container {
    constructor() {
        super();

        let winds = new Container();

        let windUp = new Sprite(AssetsManager.getTexture('wind.png'));
        winds.addChild(windUp);

        let windDown = new Sprite(AssetsManager.getTexture('wind.png'));
        windDown.scale.set(-1);
        windDown.x = windUp.width;
        windDown.y = windUp.height * 2 - windDown.height * 0.215;
        winds.addChild(windDown);

        if (
            window.innerWidth >= window.innerHeight ||
            Math.pow(winds.width / winds.height, -1) * window.innerWidth > window.innerHeight
        ) {
            winds.scale.set(window.innerWidth / winds.width);
        } else {
            winds.scale.set(window.innerHeight / winds.height);
        }

        winds.x = window.innerWidth / 2 - winds.width / 2;
        winds.y = window.innerHeight / 2 - winds.height / 2;
        this.addChild(winds);

        let windsGradient = new Sprite(AssetsManager.getTexture('70gradient.png'));
        windsGradient.tint = 0x15355e;
        windsGradient.scale.set((window.innerHeight / windsGradient.height) * 2);
        windsGradient.anchor.set(0.5, 0.5);
        windsGradient.x = window.innerWidth / 2;
        windsGradient.y = window.innerHeight;
        this.addChild(windsGradient);
    }
}

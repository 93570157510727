import gsap from 'gsap';
import { CustomEase, PixiPlugin } from 'gsap/all';
import * as PIXI from 'pixi.js';
import { AssetsManager } from './game/AssetsManager';
import Game from './game/Game';
import { ProgressManager } from './game/ProgressManager';
import { LoadingScene } from './scenes/LoadingScene';
import { StartScreenScene } from './scenes/StartScreenScene';
import { Adaptive } from './utils/Adaptive';
import Api from './services/api';
import Localization from './services/localization';

const main = async () => {
    // Init Game API
    await Api.init({ isUsingLocalStorage: true });

    // Init Localization
    await Localization.init(Api.gameData.localization);
    // await Localization.init(translations);

    // Init game manager
    Game.init(
        new PIXI.Application({
            antialias: true,
            autoDensity: true,
            resolution: 2,
        })
    );

    ProgressManager.onGameStart();

    // Display application properly
    document.body.style.margin = '0';
    Game.app.renderer.view.style.position = 'absolute';
    Game.app.renderer.view.style.display = 'block';

    Game.app.renderer.on('resize', (width: number, height: number) => {
        Adaptive.onResize(width, height);
        Game.onResize(width, height);
    });

    Game.app.renderer.resize(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', (e: UIEvent) => {
        Game.app.renderer.resize(window.innerWidth, window.innerHeight);
    });

    // Preload necessary assets
    await AssetsManager.preload();

    document.body.appendChild(Game.app.view);

    // Setup GSAP animation toolkit.
    gsap.registerPlugin(CustomEase);
    gsap.registerPlugin(PixiPlugin);
    PixiPlugin.registerPIXI(PIXI);

    Game.app.ticker.stop();
    gsap.ticker.add(() => {
        Game.app.ticker.update();
    });

    Game.app.ticker.add((delta: number) => {
        Game.update(delta);
    });

    AssetsManager.queneAddFolders(['ui', 'data', 'game']);
    Game.switchScene(
        new LoadingScene(() => {
            Game.switchScene(new StartScreenScene());
        })
    );

    Game.app.stage.addChild(Game.container);
};

main();

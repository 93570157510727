import { Container, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';

export class Circle extends Container {
    private circle: Sprite;

    get iconWidth() {
        return this.circle.width * this.scale.x;
    }

    get iconHeight() {
        return this.circle.height * this.scale.y;
    }

    constructor(type: CircleTypes) {
        super();

        this.circle = new Sprite(AssetsManager.getTexture('circles/' + CircleTypes[type] + '.png'));
        this.circle.anchor.set(0.5, 0.5);

        let backlight = new Sprite(AssetsManager.getTexture('80gradient.png'));
        backlight.tint = 0x64a2ff;
        backlight.scale.set((this.circle.width * 2.7) / backlight.width);
        backlight.anchor.set(0.5, 0.5);
        backlight.y = this.circle.y - this.circle.height * 0.1;

        this.addChild(backlight);
        this.addChild(this.circle);
    }
}

export enum CircleTypes {
    Waves,
    Veszitettel,
    Nyertel,
}

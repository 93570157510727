import { Application, Container, Graphics, Sprite, Text } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import { t } from '../services/localization';
import { Signal } from '../utils/Signals';

export class PopUp extends Container {
    type: PopUpType;

    readonly CloseSignal: Signal = new Signal();
    readonly AcceptSignal: Signal = new Signal();

    constructor(type: PopUpType) {
        super();
        this.type = type;

        let bg = new Graphics().beginFill(0xffffff).drawRoundedRect(0, 0, 343, 285, 16);
        this.addChild(bg);

        let data: string[] = [];

        switch (type) {
            case PopUpType.NoHP:
                data = [t('modal_nohp_title'), t('modal_nohp_caption'), t('modal_nohp_btn')];
                break;
            case PopUpType.TimesUp:
                data = [
                    t('modal_timesup_title'),
                    t('modal_timesup_caption'),
                    t('modal_timesup_btn'),
                ];

                break;
            case PopUpType.Quit:
                data = [t('modal_quit_title'), t('modal_quit_caption'), t('modal_quit_btn')];

                break;
        }

        let header = new Text(data[0], {
            fontFamily: 'SFProDisplay-Bold',
            fontSize: 24,
            fill: ['#000000'],
            width: 310,
            align: 'center',
        });
        header.position.set(bg.width / 2, bg.height / 3 - 35);
        header.anchor.set(0.5, 0);
        this.addChild(header);

        let desc = new Text(data[1], {
            fontFamily: 'SFProDisplay-Regular',
            fontSize: 18,
            fill: ['#000000'],
            align: 'center',
        });
        desc.position.set(bg.width / 2, bg.height / 2 - 10);
        desc.anchor.set(0.5, 0.5);
        this.addChild(desc);

        let button = new Graphics()
            .beginFill(0x1a2e94)
            .drawRoundedRect(16, (bg.height * 2) / 3, 310, 60, 12);
        this.addChild(button);

        let buttonLabel = new Text(data[2], {
            fontFamily: 'SFProDisplay-Bold',
            fontSize: 16,
            fill: ['#FFFFFF'],
            align: 'center',
        });
        buttonLabel.position.set(16 + 310 / 2, (bg.height * 2) / 3 + button.height / 2 - 1);
        buttonLabel.anchor.set(0.5, 0.5);
        this.addChild(buttonLabel);

        let closeIcon = new Sprite(AssetsManager.getTexture('crossmark.png'));
        closeIcon.position.set(312, 20);
        this.addChild(closeIcon);

        button.interactive = true;
        button.on('pointerdown', () => {
            this.AcceptSignal.trigger();
        });

        closeIcon.interactive = true;
        closeIcon.on('pointerdown', () => {
            this.CloseSignal.trigger();
        });
    }
}

export enum PopUpType {
    NoHP,
    TimesUp,
    Quit,
}

import axios from 'axios';

import { IApiConfig } from '../../interfaces/IApiConfig';
import { IGameData } from '../../interfaces/IGameData';
import { IGameProgress } from '../../interfaces/IGameProgress';

let isNewProgressFlow: boolean;

const { token, article_id, api_url, data: legacyData } = getUrlParameters();
const { GAME_API_URL, GAME_ID } = window;

const axiosConnex = axios.create();
const axiosGame = axios.create();

const Api = {
    gameProgress: {
        storage_key: 'mahjong-hu',
        completedLevels: [],
        hp: 0,
        lastPlayed: 0,
    } as IGameProgress,

    gameData: {
        levels: [],
        assets: [],
        localization: null,
        configuration: null,
    } as IGameData,

    init,
    rewardUser,
    exitGame,
    loseGame,
    getGameData,
    getProgress,
    saveProgress,
    handleLevelComplete,
};

async function init({ isUsingLocalStorage }: IApiConfig) {
    isUsingLocalStorage && useLocalStorage();

    if (token && article_id && api_url) {
        isNewProgressFlow = true;
        axiosConnex.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        console.warn('Using legacy progress flow');
    }

    await Api.getGameData();
    await Api.getProgress();
}

async function handleLevelComplete(key: string) {
    try {
        const isBonusReward = !Api.gameProgress.completedLevels.includes(key);
        const rewardType = isBonusReward ? 'PRIMARY' : 'SECONDARY';

        if (isBonusReward) {
            Api.gameProgress.completedLevels.push(key);
            await Api.saveProgress();
        }

        await Api.rewardUser(rewardType);
        return isBonusReward;
    } catch (error) {
        console.error('Handle level complete error:', error);
    }
}

async function getGameData() {
    try {
        const { data } = await axiosGame.get(`${GAME_API_URL}/games/${GAME_ID}`);
        data && setGameData(data);
        return data;
    } catch (error) {
        console.error('Get config error:', error);
        return null;
    }
}

async function getProgress() {
    try {
        let data;

        if (isNewProgressFlow) {
            const res = await axiosConnex.get(api_url, {
                params: { article_id: article_id },
            });
            data = res.data.game_progress;
        } else {
            data = legacyData;
        }

        data && setGameProgress(JSON.parse(data));
    } catch (error) {
        console.error('Get progress error:', error);
        return null;
    }
}

function setGameProgress(data: any) {
    for (const key in Api.gameProgress) {
        // @ts-ignore
        Api.gameProgress[key as keyof IGameProgress] = data[key];
    }
}

function setGameData(data: any) {
    for (const key in Api.gameData) {
        Api.gameData[key as keyof IGameData] = data[key];
    }
}

function useLocalStorage() {
    // @ts-ignore
    Api.getProgress = getLocalProgress;
    Api.saveProgress = saveLocalProgress;

    console.error(
        `
WARNING! 
Api methods getProgress() and saveProgress() are overrided! 
Using LocalStorage to persist data!
      `
    );
}

async function saveProgress() {
    try {
        const data = JSON.stringify(Api.gameProgress);

        if (isNewProgressFlow) {
            await axiosConnex.post(api_url, { article_id, game_progress: data });
        } else {
            if (window.webkit && window.webkit.messageHandlers) {
                window.webkit.messageHandlers.saveData.postMessage({ data });
            } else if (window.AndroidEvent && window.AndroidEvent.readArticleWithType) {
                window.AndroidEvent.saveData({ data });
            } else if (window.top && window.top.postMessage) {
                window.top.postMessage({ message: 'saveData', data }, '*');
            }
        }

        console.log('Game progress saved');
    } catch (error) {
        console.error('Game progress save error:', error);
    }
}

function rewardUser(type: string) {
    console.log('Reward user type:', type);
    try {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.rewardUser.postMessage({ type });
        } else if (window.AndroidEvent && window.AndroidEvent.rewardUserWithTypeAndProgress) {
            const data = JSON.stringify(Api.gameProgress);
            window.AndroidEvent.rewardUserWithTypeAndProgress(type, data);
        } else if (window.top && window.top.postMessage) {
            window.top.postMessage({ message: 'rewardUser', type }, '*');
        }
    } catch (error) {
        console.error('Reward user error:', error);
    }
}

function exitGame() {
    console.log('Exit game');
    try {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.close.postMessage({});
        } else if (window.AndroidEvent && window.AndroidEvent.closeWebView) {
            window.AndroidEvent.closeWebView();
        } else if (window.top && window.top.postMessage) {
            window.top.postMessage('closeWebView', '*');
        }
    } catch (error) {
        console.error('Exit game error:', error);
    }
}

function loseGame() {
    console.log('Lose game');
    try {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.loseGame();
        } else if (window.AndroidEvent && window.AndroidEvent.loseGame) {
            window.AndroidEvent.loseGame();
        } else if (window.top && window.top.postMessage) {
            window.top.postMessage('loseGame', '*');
        }
    } catch (error) {
        console.error('Lose game error:', error);
    }
}

async function getLocalProgress() {
    try {
        // @ts-ignore
        const data = JSON.parse(localStorage.getItem(Api.gameProgress.storage_key));
        setGameProgress(data);
    } catch (error) {
        console.warn(error);
    }
}

async function saveLocalProgress() {
    try {
        const data = JSON.stringify(Api.gameProgress);
        localStorage.setItem(Api.gameProgress.storage_key, data);
    } catch (error) {
        console.warn(error);
    }
}

function getUrlParameters(): any {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const entries = urlParams.entries();
        const parameters = {};

        for (const entry of entries) {
            // @ts-ignore
            parameters[entry[0]] = entry[1];
        }
        return parameters;
    } catch (error) {
        console.log(error);
    }
}

export default Api;

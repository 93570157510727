import { Container, Filter, Graphics, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import { IGameDataLevel } from '../interfaces/IGameDataLevel';

export class LevelBar extends Container {
    status: LevelStatus;
    sprite: Sprite;
    data: IGameDataLevel;

    constructor(data: IGameDataLevel, status: LevelStatus) {
        super();
        this.status = status;
        this.data = data;

        this.sprite = new Sprite(
            AssetsManager.getTexture('ui/levels/' + data.configuration.Background_List + '.png')
        );
        this.addChild(this.sprite);

        switch (status) {
            case LevelStatus.Locked:
                this.sprite.tint = 0x808080;

                let lock = new Sprite(AssetsManager.getTexture('levels/lock.png'));
                lock.anchor.set(0.5, 0.5);
                lock.position.set(this.width / 2, this.height / 2);
                this.addChild(lock);
                break;

            case LevelStatus.Completed:
                this.sprite.tint = 0x808080;

                let completed = new Sprite(AssetsManager.getTexture('levels/completed.png'));
                completed.anchor.set(0.5, 0.5);
                completed.position.set(this.width / 2, this.height / 2);
                this.addChild(completed);
                break;
        }
    }
}

export enum LevelStatus {
    Active,
    Completed,
    Locked,
}

import gsap from 'gsap';
import { Container, Graphics, Sprite, Text, TextStyle } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import Api from '../services/api';
import { ProgressManager } from '../game/ProgressManager';
import { Scene } from '../game/Scene';
import { IGameDataLevel } from '../interfaces/IGameDataLevel';
import { Button, ButtonType } from '../prefabs/Button';
import { LevelBar, LevelStatus } from '../prefabs/LevelBar';
import { PopUp, PopUpType } from '../prefabs/PopUp';
import { Adaptive } from '../utils/Adaptive';
import { PlayScene } from './PlayScene';
import { RulesScene } from './RulesScene';
import { StartScreenScene } from './StartScreenScene';
import { LoadingScene } from './LoadingScene';

export class LevelsScene extends Scene {
    constructor() {
        super();

        let wind = new Sprite(AssetsManager.getTexture('wind.png'));
        wind.scale.set((window.innerHeight * 1.2) / wind.height);
        this.addChild(wind);

        let back = new Sprite(AssetsManager.getTexture('back.png'));
        back.scale.set(((Adaptive.contentWidth - 20) * 0.08) / back.height);
        back.position.set(window.innerWidth * 0.05, (Adaptive.contentWidth - 20) * 0.08);
        back.tint = 0x000000;
        back.interactive = true;
        back.on('pointerdown', this.backPressed);
        //this.addChild(back);

        let hpBar = new Sprite(AssetsManager.getTexture('levels/hpbar.png'));
        hpBar.scale.set((Adaptive.contentWidth - 30) / hpBar.width);
        hpBar.anchor.set(0.5, 0.5);
        hpBar.position.set(
            window.innerWidth / 2,
            window.innerHeight / 2 - Adaptive.contentHeight * 0.38
        );
        this.addChild(hpBar);

        let hpText = new Text('x' + ProgressManager.HP, {
            fontFamily: 'SFProDisplay-Black',
            fontSize: hpBar.height * 0.52,
            fill: 0x0056c3,
        });
        hpText.anchor.set(0.5, 0.5);
        hpText.position.set(hpBar.x + hpBar.width * 0.002, hpBar.y - hpBar.height * 0.07);
        this.addChild(hpText);

        // this.button = new Button(ButtonType.Blue, 'Start');
        // this.button.pivot.set(this.button.width / 2, this.button.height / 2);
        // this.button.scale.set((Adaptive.contentWidth - 30) / this.button.width);
        // this.button.position.set(
        //     window.innerWidth / 2,
        //     window.innerHeight / 2 + Adaptive.contentHeight * 0.33
        // );
        // this.button.alpha = 0.4;
        // this.button.on('pointerdown', this.startPressed.bind(this));
        // this.addChild(this.button);

        let { levels } = Api.gameData;

        let levelsContainer = new Container();

        levels.forEach(async (data: IGameDataLevel, index: number) => {
            const completed = Api.gameProgress.completedLevels.includes(data.key);
            let level = new LevelBar(data, Number(completed));
            level.scale.set((hpBar.width - 20) / 2 / level.width);
            level.position.set(
                (level.width + 15) * (index % 2),
                (level.height + 15) * Math.floor(index / 2)
            );
            level.interactive = true;
            level.on('pointerdown', () => {
                this.selectLevel(level);
            });
            levelsContainer.addChild(level);
        });

        levelsContainer.pivot.set(levelsContainer.width / 2, 0);
        levelsContainer.scale.set((Adaptive.contentWidth - 30) / levelsContainer.width);
        levelsContainer.position.set(window.innerWidth / 2, hpBar.y + hpBar.height / 1.2);
        this.addChild(levelsContainer);

        let Beszallas = new Sprite(AssetsManager.getTexture('Beszallas.png'));
        Beszallas.pivot.set(Beszallas.width, Beszallas.height);
        Beszallas.scale.set((back.height * 2) / Beszallas.height);
        Beszallas.position.set(
            window.innerWidth / 2 + Adaptive.contentWidth / 2 - Beszallas.width / 3,
            window.innerHeight - back.y / 2
        );
        Beszallas.tint = 0x000000;
        Beszallas.interactive = true;
        Beszallas.on('pointerdown', this.rulesPressed);
        this.addChild(Beszallas);

        let Jatek = new Sprite(AssetsManager.getTexture('Jatek.png'));
        Jatek.pivot.set(0, Jatek.height);
        Jatek.scale.set((back.height * 2) / Jatek.height);
        Jatek.position.set(
            innerWidth / 2 - Adaptive.contentWidth / 2 + Beszallas.width / 2.85,
            window.innerHeight - back.y / 2
        );
        Jatek.tint = 0x000000;
        Jatek.interactive = true;
        Jatek.on('pointerdown', this.backPressed);
        this.addChild(Jatek);

        // Colored bg
        Game.app.renderer.backgroundColor = 0xffffff;
    }

    backPressed() {
        Game.switchScene(new StartScreenScene());
    }

    rulesPressed() {
        Game.switchScene(new RulesScene());
    }

    selectLevel(level: LevelBar) {
        if (ProgressManager.HP > 0) {
            level.data.assets.forEach((asset) => {
                AssetsManager.queneAddUrl(asset.url);
            });

            Game.switchScene(
                new LoadingScene(() => {
                    Game.switchScene(new PlayScene(level.data));
                })
            );
        } else this.onNoHP();
    }

    onNoHP() {
        let bg = new Graphics()
            .beginFill(0x000000, 0.7)
            .drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.addChild(bg);

        // Make sure that player interacte with anything just pop up
        bg.interactive = true;

        let popUp = new PopUp(PopUpType.NoHP);
        popUp.pivot.set(popUp.width / 2, popUp.height / 2);
        popUp.scale.set((Adaptive.contentWidth - 20) / popUp.width);
        popUp.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.addChild(popUp);

        gsap.fromTo(popUp, { alpha: 0 }, { alpha: 1, duration: 0.4 });

        const fn = () => {
            gsap.to([popUp, bg], {
                alpha: 0,
                duration: 0.2,
                onComplete: () => {
                    this.removeChild(popUp);
                    this.removeChild(bg);
                },
            });
        };

        popUp.CloseSignal.on(fn.bind(this));
        popUp.AcceptSignal.on(fn.bind(this));
    }
}

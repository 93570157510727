import Api from '../services/api';

export class ProgressManager {
    static get HP(): number {
        return Api.gameProgress.hp;
    }

    static set HP(value: number) {
        value = Math.min(3, Math.max(0, value));
        Api.gameProgress.hp = value;
        Api.saveProgress();
    }

    static onGameStart() {
        let curDate = new Date().getUTCDate();

        if (curDate != Api.gameProgress.lastPlayed) {
            Api.gameProgress.lastPlayed = curDate;
            Api.gameProgress.hp = 3;
            Api.saveProgress();
        }
    }
}

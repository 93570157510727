import { Container, Sprite } from 'pixi.js';
import { AssetsManager } from '../game/AssetsManager';
import Game from '../game/Game';
import Api from '../services/api';
import { Scene } from '../game/Scene';
import { Tile } from '../prefabs/tile';
import { TileType } from '../prefabs/TileTypes';
import { Button, ButtonType } from '../prefabs/Button';
import { Adaptive } from '../utils/Adaptive';
import { Circle, CircleTypes } from '../prefabs/Circle';
import { Winds } from '../prefabs/Winds';
import { PlayScene } from './PlayScene';
import { RulesScene } from './RulesScene';
import { LevelsScene } from './LevelsScene';
import { t } from '../services/localization';

export class StartScreenScene extends Scene {
    constructor() {
        super();

        let winds = new Winds();
        this.addChild(winds);

        let circle = new Circle(CircleTypes.Waves);
        circle.scale.set((Adaptive.contentWidth - 20) / circle.iconWidth);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.addChild(circle);

        let mahjong = new Container();

        [
            TileType.M,
            TileType.A,
            TileType.H,
            TileType.J,
            TileType.O,
            TileType.N,
            TileType.G,
        ].forEach((type, index) => {
            let letter = new Tile(type);
            letter.scale.set((circle.width * 0.08) / letter.width);
            letter.x += (letter.width + 5) * index;

            mahjong.addChild(letter);
        });

        mahjong.pivot.set(mahjong.width, 0);
        mahjong.scale.set((circle.iconWidth * 0.75) / mahjong.width);
        mahjong.x = circle.x + circle.iconWidth / 2.05;
        mahjong.y = circle.y - circle.iconHeight / 2.22;
        this.addChild(mahjong);

        let winston = new Sprite(AssetsManager.getTexture('winston.png'));
        winston.scale.set((circle.iconWidth * 0.92) / winston.width);
        winston.x = circle.x - circle.iconWidth / 2.1;
        winston.y = circle.y - circle.iconHeight / 1.58;
        this.addChild(winston);

        let button = new Button(ButtonType.White, t('start_btn'));
        button.pivot.set(button.width / 2, button.height / 2);
        button.scale.set((Adaptive.contentWidth - 30) / button.width);
        button.position.set(
            window.innerWidth / 2,
            window.innerHeight / 2 + Adaptive.contentHeight * 0.33
        );
        button.interactive = true;
        button.on('pointerdown', this.startPressed);
        this.addChild(button);

        let back = new Sprite(AssetsManager.getTexture('back.png'));
        back.scale.set((circle.iconHeight * 0.08) / back.height);
        back.position.set(window.innerWidth * 0.05, (Adaptive.contentWidth - 20) * 0.08);
        back.interactive = true;
        back.on('pointerdown', this.backPressed);
        //this.addChild(back);

        let Beszallas = new Sprite(AssetsManager.getTexture('Beszallas.png'));
        Beszallas.pivot.set(Beszallas.width, Beszallas.height);
        Beszallas.scale.set((back.height * 2) / Beszallas.height);
        Beszallas.position.set(
            window.innerWidth / 2 + Adaptive.contentWidth / 2 - Beszallas.width / 3,
            window.innerHeight - back.y / 2
        );
        Beszallas.interactive = true;
        Beszallas.on('pointerdown', this.rulesPressed);
        this.addChild(Beszallas);

        let Jatek = new Sprite(AssetsManager.getTexture('Jatek.png'));
        Jatek.pivot.set(0, Jatek.height);
        Jatek.scale.set((back.height * 2) / Jatek.height);
        Jatek.position.set(
            innerWidth / 2 - Adaptive.contentWidth / 2 + Beszallas.width / 2.85,
            window.innerHeight - back.y / 2
        );
        Jatek.interactive = true;
        Jatek.on('pointerdown', this.backPressed);
        this.addChild(Jatek);

        // Temporary colored bg
        Game.app.renderer.backgroundColor = 0x1a2e94;
    }

    startPressed() {
        Game.switchScene(new LevelsScene());
    }

    backPressed() {
        Api.exitGame();
    }

    rulesPressed() {
        Game.switchScene(new RulesScene());
    }
}

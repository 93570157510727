import { Application, Container, Sprite, Graphics } from 'pixi.js';

export class Adaptive {
    static readonly aspectRatio = 420 / 812;

    // Focus area for adaptive design
    static contentWidth: number = 0;
    static contentHeight: number = 0;

    static onResize(width: number, height: number) {
        if (
            width >= height ||
            Math.pow(this.aspectRatio, -1) * window.innerWidth > window.innerHeight
        ) {
            this.contentWidth = this.aspectRatio * window.innerHeight;
            this.contentHeight = window.innerHeight;
        } else {
            this.contentWidth = window.innerWidth;
            this.contentHeight = Math.pow(this.aspectRatio, -1) * window.innerWidth;
        }
    }
}

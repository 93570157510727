// import i18next from 'i18next';

// TODO
const Localization = {
    init,
};

let translations = {};

function init(localizationData: any) {
    translations = localizationData;
    // return i18next.init({
    //     lng: 'en',
    //     debug: false,
    //     returnObjects: true,
    //     resources: {
    //         en: localizationData,
    //     },
    // });
}

export function t(key: any, options?: any) {
    // @ts-ignore
    return translations[key] as any;
    //return i18next.t(key, options) as string;
}

export default Localization;

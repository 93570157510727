import { Text } from 'pixi.js';
import Game from '../game/Game';
import { Scene } from '../game/Scene';
import { Tile } from '../prefabs/tile';
import { TileType } from '../prefabs/TileTypes';
import { Button, ButtonType } from '../prefabs/Button';
import { Adaptive } from '../utils/Adaptive';
import { Circle, CircleTypes } from '../prefabs/Circle';
import { Winds } from '../prefabs/Winds';
import { ProductCardScene } from './ProductCardScene';
import { t } from '../services/localization';

export class GameOverScene extends Scene {
    constructor(type: GameOverType) {
        super();

        let winds = new Winds();
        this.addChild(winds);

        let circle = new Circle(
            type == GameOverType.Lose ? CircleTypes.Veszitettel : CircleTypes.Nyertel
        );
        circle.scale.set((Adaptive.contentWidth - 20) / circle.iconWidth);
        circle.position.set(window.innerWidth / 2, window.innerHeight / 2.08);
        this.addChild(circle);

        let mahjong = [
            TileType.M,
            TileType.A,
            TileType.H,
            TileType.J,
            TileType.O,
            TileType.N,
            TileType.G,
        ];

        mahjong.forEach((letterType) => {
            let letter = new Tile(letterType);
            letter.pivot.set(letter.width / 2, letter.height / 2);
            letter.scale.set((circle.iconWidth * 0.19) / letter.width);

            switch (letterType) {
                case TileType.M:
                    letter.x = circle.x + circle.iconWidth * 0.1;
                    letter.y = circle.y + circle.iconHeight * 0.75;
                    letter.angle = -10;
                    break;
                case TileType.A:
                    letter.x = circle.x + circle.iconWidth * 0.44;
                    letter.y = circle.y + circle.iconHeight * 0.2;
                    letter.angle = 21;
                    break;
                case TileType.H:
                    letter.x = circle.x + circle.iconWidth * 0.54;
                    letter.y = circle.y + circle.iconHeight * 0.35;
                    letter.angle = -27;
                    break;
                case TileType.J:
                    letter.x = circle.x + circle.iconWidth * 0.5;
                    letter.y = circle.y + circle.iconHeight * 0.71;
                    letter.angle = 20;
                    break;
                case TileType.O:
                    letter.x = circle.x + circle.iconWidth * 0.22;
                    letter.y = circle.y + circle.iconHeight * 0.2;
                    letter.angle = 0;
                    break;
                case TileType.N:
                    letter.x = circle.x + circle.iconWidth * 0.045;
                    letter.y = circle.y + circle.iconHeight * 0.27;
                    letter.angle = -11;
                    break;
                case TileType.G:
                    letter.x = circle.x + circle.iconWidth * 0.015;
                    letter.y = circle.y + circle.iconHeight * 0.55;
                    letter.angle = 27;
                    break;
            }

            letter.x *= 2;
            letter.y *= 2;
            letter.x -= window.innerWidth;
            letter.y -= window.innerHeight;

            this.addChild(letter);
        });

        let button = new Button(
            ButtonType.White,
            type == GameOverType.Lose ? t('gameover_lose_btn') : t('gameover_win_btn')
        );
        button.pivot.set(button.width / 2, button.height / 2);
        button.scale.set((Adaptive.contentWidth - 30) / button.width);
        button.position.set(window.innerWidth / 2, window.innerHeight * 0.88);
        button.interactive = true;
        button.on('pointerdown', this.buttonPressed);
        this.addChild(button);

        let descText = '';
        switch (type) {
            case GameOverType.WinPrimary:
                descText = t('gameover_primary');
                break;
            case GameOverType.WinSecondary:
                descText = t('gameover_secondary');
                break;
            case GameOverType.Lose:
                descText = t('gameover_lose');
                break;
        }

        let desc = new Text(descText, {
            fontFamily: 'SFProDisplay-Regular',
            fontSize: button.height * 0.27,
            fill: ['#FFFFFF'],
            align: 'center',
        });
        desc.anchor.set(0.5, 0.5);
        desc.position.set(
            button.x,
            circle.y +
                circle.iconHeight / 2 +
                (button.y - button.height / 2 - circle.y - circle.iconWidth / 2) / 2
        );
        this.addChild(desc);

        // Temporary colored bg
        Game.app.renderer.backgroundColor = 0x1a2e94;
    }

    buttonPressed() {
        Game.switchScene(new ProductCardScene());
    }
}

export enum GameOverType {
    WinPrimary,
    WinSecondary,
    Lose,
}

import Game from './Game';

export class AssetsManager {
    static assets: any;

    static preload(): Promise<void> {
        return new Promise<void>((resolve) => {
            Game.app.loader.baseUrl = 'assets';

            Game.app.loader.add('assets.json');

            Game.app.loader.onComplete.once(() => {
                this.assets = this.getData('assets.json');

                this.queneAddFolders(['preload']);
                Game.app.loader.load();
                Game.app.loader.onComplete.once(resolve);
            });

            Game.app.loader.load();
        });
    }

    static queneAddFolders(folders: string[]) {
        folders.forEach((name) => {
            if (this.assets[name]) {
                this.assets[name].forEach((file: string) => {
                    Game.app.loader.add(name + '/' + file);
                });
            } else {
                console.warn('THERE IS NO FOLDER - ' + name);
            }
        });
    }

    static queneAddUrl(url: string) {
        if (Game.app.loader.resources[url]) return;

        Game.app.loader.add(url);
    }

    static getData(path: string): any {
        return this.getFile(path).data;
    }

    static getTexture(path: string): any {
        return this.getFile(path).texture;
    }

    static getFile(path: string): any {
        let file = Game.app.loader.resources[path];

        if (this.assets)
            Object.keys(this.assets).forEach((folder) => {
                if (this.getFolderPath(path, folder)) {
                    file = this.getFolderPath(path, folder);
                }
            });

        return file;
    }

    static getFolderPath(path: string, folder: string): any {
        return Game.app.loader.resources[folder + '/' + path];
    }
}
